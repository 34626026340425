// IMPORT VENDOR
import $ from 'jquery';
window.$ = window.jQuery = $;
import debounce from 'lodash/debounce';
import { Foundation } from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
Foundation.plugin(MediaQuery, 'MediaQuery');
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
Foundation.plugin(DropdownMenu, 'DropdownMenu');
import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu';
Foundation.plugin(AccordionMenu, 'AccordionMenu');
import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal');
import AOS from 'aos';

// IMPORT DES COMPONENTS
import { utils } from './components/_utils';
//import { bodyPaddingTop } from './components/_bodyPaddingTop';
import ScrollToLink from './components/_scrollTo';
import { header } from './components/header';
import { swiper } from './components/_swiper';
import { video } from './components/_video';

// Pages
import { homePage } from './pages/home';
import { honoraire } from './pages/honoraire';
import { singleOffre } from './pages/single-offre';
import { lesOffres } from './pages/les-offres';
import { recherche } from './pages/recherche';


// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
    utils.checkDevice();
    jQuery(window).on('resize', debounce(function () {
        utils.checkDevice();
    }, 100));

    $(document).foundation();

    // Init scrollTo class
    let scrollTo = new ScrollToLink();
    scrollTo.init();

    // Padding top de la hauteur du header sur le <body>
    //bodyPaddingTop.init();

    header.readyFn();
    swiper.readyFn();
    video.readyFn();

    // Execute le script de la page dans laquelle on se trouve
    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
        case bodyClassList.contains('home'):
            homePage.readyFn();
            break;
        case bodyClassList.contains('page-template-page-honoraire'):
            honoraire.readyFn();
            break;
        case bodyClassList.contains('page-template-page-les-offres'):
            lesOffres.readyFn();
            break;
        case bodyClassList.contains('single-offre'):
            singleOffre.readyFn();
            break;
        case bodyClassList.contains('page-template-page-acheter'):
            recherche.readyFn();
            break;
    }
});


// ----------------------------------- WINDOW LOAD --------------------------------------
// --------------------------------------------------------------------------------------
$(window).on('load', function () {
    document.body.classList.add('loaded');
    AOS.init({
        once: true,
        anchorPlacement: 'top-center',
        offset: 200,
    });

});