// IMPORT VENDOR
import Swiper from 'swiper';
import { isEmptyObject } from 'jquery';


const recherche = {
    readyFn: function () {
        console.log("ready les offres not ready");
        this.recherche();

    },

    recherche: function () {

        const submitButton = $(".button.submit");
        const previousButton = document.querySelector('#prev')
        const nextButton = document.querySelector('#next')
        let currentStep = 0
        const part = document.querySelectorAll('.part')
        var inputsAreNotEmpty = true;

        // Next: Change UI relative to the current step and account for button permissions
        $(nextButton).on("click", function (event) {
            // Prevent default on links
            event.preventDefault();

            // Hide current tab
            if (!$(this).hasClass("disabled")) {
                $(part[currentStep]).addClass("hidden").removeClass("active");
                // Show next tab
                $(part[currentStep + 1]).removeClass("hidden").addClass("active");
                currentStep += 1;

                $(nextButton).attr("data-step", currentStep);
                $(previousButton).attr("data-step", currentStep);

                $(this).addClass("disabled");

                if (currentStep == 4) {
                    $(nextButton).hide();
                    $(submitButton).show();
                } else {
                    $(nextButton).show();
                    $(submitButton).hide();
                }
            }

            if ($("#part-un").hasClass("hidden")) {
                $("#prev").removeClass("hidden");
            }

            if ($(".grid-recherche").attr("data-part-un") == "local-pro") {
                $("#part-trois .chambre").text("Nombre de bureaux");

            } else {
                $("#part-trois .chambre").text("Nombre de chambres");
            }

        });


        $(previousButton).on("click", function (event) {
            event.preventDefault()
            // Hide current tab
            $(nextButton).removeClass("disabled");
            $(nextButton).show();
            $(submitButton).hide();
            if (currentStep > 0) {
                $(part[currentStep]).addClass("hidden");
                $(part[currentStep]).removeClass("active");
                // Show the previous tab
                $(part[currentStep - 1]).removeClass("hidden");
                $(part[currentStep - 1]).addClass("active");
                currentStep -= 1

                $(nextButton).attr("data-step", currentStep);
                $(previousButton).attr("data-step", currentStep);
            }
        })

        /* BARRE DE PROGRESSION */
        $(".pagination .button").on("click", function () {
            $(".cell-progress .step").text(currentStep + 1);
            $(".cell-progress .progress").removeClass(function (index, className) {
                return (className.match(/\bprogress-\S+/g) || []).join(' ');
            });
            $(".cell-progress .progress").addClass("progress-" + currentStep);
        });

        /* PART 1 */
        $("#part-un .wrapper").on("click", function () {
            $("#part-un .wrapper").removeClass("actif");
            $(this).addClass("actif");
            $(nextButton).removeClass("disabled");
            var valuePartUn = $(this).data("form");
            $("#recherche-form #type-form").val(valuePartUn);
            var slugifyValuePartUn = valuePartUn.replace(/\s+/g, '-');
            $(".grid-recherche").attr('data-part-un', slugifyValuePartUn);
            console.log("Valeur input Part 1 : " + $("#recherche-form #type-form").val());
        });

        /* PART 2 */
        $("#part-deux .wrapper").on("click", function () {
            $("#part-deux .wrapper").removeClass("actif");
            $(this).addClass("actif");
            $(nextButton).removeClass("disabled");
            var valuePartDeux = $(this).data("form");
            $("#recherche-form #goal-form").val(valuePartDeux);
            console.log("Valeur input part 2 : " + $("#recherche-form #goal-form").val());
        });

        /* PART 3 */
        $(".input-part-trois").on("change", function () {

            var valueWhere = $("#where").val();
            var valueChambre = $("#chambre").val();
            var valueSurfaceMin = $("#surface-min").val();
            var valueSurfaceMax = $("#surface-max").val();

            $(nextButton).removeClass("disabled");

            $("#where-form").val(valueWhere);
            $("#chambre-form").val(valueChambre);
            $("#surface-min-form").val(valueSurfaceMin);
            $("#surface-max-form").val(valueSurfaceMax);

        });

        /* PART 4 */

        $(".toggle-exterieur").on("change", function () {
            var valueToggleExterieur = $("input[name='exterieur']:checked").val();
            if (valueToggleExterieur == "oui") {
                $(".wrapper-ext").addClass("actif");
                $("#exterieur-form").val(valueToggleExterieur);

            } else {
                $(".wrapper-ext").removeClass("actif");
                $("#exterieur-form").val(valueToggleExterieur);
            }
        });

        $(".type-exterieur").on("change", function () {
            var valueTypeExterieur = $("input[name='type-exterieur']:checked").val();
            if (valueTypeExterieur === "") {
                return false;
            } else {
                $("#type-exterieur-form").val(valueTypeExterieur);
            }
        });

        $("#precise-exterieur").on("change", function () {
            var valuePrecise = $("#precise-exterieur").val();
            $("#precise-exterieur-form").val(valuePrecise);
        });

        $(".toggle-travaux").on("change", function () {
            var valueToggleTravaux = $("input[name='travaux']:checked").val();
            $("#travaux-form").val(valueToggleTravaux);
        });

        $("#budget").on("change", function () {
            var valueBudget = $("#budget").val();
            $("#budget-form").val(valueBudget);
        });

        $(".toggle-financement").on("change", function () {
            var valueFinancement = $("input[name='financement']:checked").val();
            $("#financement-form").val(valueFinancement);
        });

        var allValuesNotEmpty = false;

        $(".toggle-exterieur, .toggle-travaux, #budget, .toggle-financement").on("change", function () {
            var valueToggleExterieur = $("input[name='exterieur']:checked").val();
            var valueToggleTravaux = $("input[name='travaux']:checked").val();
            var valueBudget = $("#budget").val();
            var valueFinancement = $("input[name='financement']:checked").val();

            if (valueToggleExterieur && valueToggleTravaux && valueBudget && valueFinancement) {
                allValuesNotEmpty = true;
            } else {
                allValuesNotEmpty = false;
            }

            // Vérifiez si toutes les valeurs ne sont pas vides
            if (allValuesNotEmpty) {
                // Toutes les valeurs sont remplies
                console.log("Toutes les valeurs sont remplies.");
                $(nextButton).removeClass("disabled");
            } else {
                console.log("Au moins une valeur est vide.");
            }
        });

        /* PART 5 */
        // Déclarez les variables conditionnelles
        console.log("test 10");
        var conditionNom = false;
        var conditionPrenom = false;
        var conditionTel = false;
        var conditionEmail = false;


        $("#message").on("input", function () {
            console.log("Valeur du textarea : " + $(this).val());
            var valueMessage = $(this).val();
            $("#message-form").val(valueMessage);
            console.log("valueMessage : " + valueMessage);

        });

        $("#nom").on("change", function () {
            var valueNom = $("#nom").val();
            $("#nom-form").val(valueNom);
            conditionNom = true;
            console.log("conditionNom : " + conditionNom);
        });

        $("#prenom").on("change", function () {
            var valuePrenom = $("#prenom").val();
            $("#prenom-form").val(valuePrenom);
            conditionPrenom = true;
            console.log("conditionPrenom : " + conditionPrenom);
        });

        $("#telephone").on("change", function () {
            var valueTel = $("#telephone").val();
            $("#tel-form").val(valueTel);
            conditionTel = true;
            console.log("conditionTel : " + conditionTel);
        });

        $("#email").on("change", function () {
            var valueEmail = $("#email").val();
            $("#email-form").val(valueEmail);
            conditionEmail = true;
            console.log("conditionEmail : " + conditionEmail);
        });

        $("#part-cinq input").on("change", function () {
            console.log("ça bouge")
            if (conditionNom == true && conditionPrenom == true && conditionTel == true && conditionEmail == true) {
                console.log("Le bouton n'est plus disabled");
                $("#form-submit").removeAttr("disabled");
                $("#form-submit").removeClass("disabled");
            }
        });
    }

}

export { recherche };