// IMPORT VENDOR
import Swiper from 'swiper';
import jQueryBridget from 'jquery-bridget';
import { isEmptyObject } from 'jquery';


const lesOffres = {
    readyFn: function () {
        console.log("ready les offres not ready");
        this.isotopeGrid();

    },

    isotopeGrid: function () {

        $(".cell-filtre .filter").on("click", function () {

            if ($(this).hasClass("actif")) {
                $(".grid-offre .cell-offre").show();
                $(".grid-offre .cell-offre").addClass("actif");
                $(this).removeClass("actif");

            } else {
                $("#min-budget").val('');
                $("#max-budget").val('');
                $("#min-surface").val('');
                $("#max-surface").val('');
                $(".cell-filtre .filter").removeClass("actif");
                $(this).addClass("actif");
                var typeBien = $(this).data("filter");
                console.log("trier par type : " + typeBien);
                $('.cell-offre').each(function () {
                    var type = $(this).data('type');
                    if (typeBien === type) {
                        $(this).show();
                        $(this).addClass("actif");
                    } else {
                        $(this).hide();
                        $(this).removeClass("actif");
                    }
                });
            }
        });


        $("#rechercher").on("click", function () {
            console.log("rechercher clicked");
            var valMinBudget = $("#min-budget").val();
            var valMaxBudget = $("#max-budget").val();
            var valMinSurface = $("#min-surface").val();
            var valMaxSurface = $("#max-surface").val();

            $('.cell-offre.actif').each(function () {
                var budget = $(this).data('budget');
                var surface = $(this).data('surface');

                if ((valMinBudget === '' || parseInt(budget, 10) >= parseInt(valMinBudget, 10)) &&
                    (valMaxBudget === '' || parseInt(budget, 10) <= parseInt(valMaxBudget, 10)) &&
                    (valMinSurface === '' || parseInt(surface, 10) >= parseInt(valMinSurface, 10)) &&
                    (valMaxSurface === '' || parseInt(surface, 10) <= parseInt(valMaxSurface, 10))) {
                    $(this).show(); // Affiche l'élément si les critères sont respectés
                } else {
                    $(this).hide(); // Cache l'élément sinon
                }
            });
        });



    }

}

export { lesOffres };