// --- Vendors ---
// --- Components ---
const header = {
    contactUrl: '',

    readyFn: function () {
        let self = this;

        $('.hide-des').on('click', function () {
            $("#menu-mobile").slideToggle();
            $("body").delay('200').toggleClass("menu_actif");
        });

        //SUB-MENU
        $(".header-menu-mobile .menu-item-has-children").click(function () {
            $(this).toggleClass("menu-item-has-children-active");
            $(".sub-menu", this).slideToggle();
        });

        $("header .menu > .menu-item").hover(function () {
            $(".sub-menu").removeClass("actif");
            $(".sub-menu", this).addClass("actif");
        });



        var position = $("header").scrollTop();
        var windowScrollTop = 0;
        $(window).on('scroll', function () {
            windowScrollTop = $(window).scrollTop();

            if (windowScrollTop > position) {
                document.getElementsByTagName('header')[0].classList.add('scrolled');
            } else {
                document.getElementsByTagName('header')[0].classList.remove('scrolled');
            }
        });


        $(".halpha-menu").on("change", function () {
            $("#container-menu-mobile").toggleClass("actif");
        });

        /* Bulle réseaux */
        $("#open-message").on("click", function () {
            $("#bulle-reseaux .wrapper-image").toggle("slide");
        });

    },
}
export { header };