// --- Vendors ---
import Swiper, { Navigation, Pagination, Thumbs, Mousewheel } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, Mousewheel]);
import { Fancybox } from "@fancyapps/ui";
// --- Components ---


const singleOffre = {
    readyFn: function () {
        console.log("Single Offre ready");

        const swiper = new Swiper('.swiper', {
            // Optional parameters
            direction: 'vertical',
            slidesPerView: 2,
            spaceBetween: 30,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        Fancybox.bind("[data-fancybox]", {
            // Your custom options
        });

        $(".cell-details-header").on('click', function () {
            $(this).toggleClass('actif');
            $("section#lien .cell-details").slideToggle();
        });

    }
}

export { singleOffre };