import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';


const honoraire = {
    readyFn: function () {
        console.log("honoraire");
    }
}

export { honoraire };